import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_digitalassistant.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Learn how conversation ai based task automation can differentiate your IT helpdesk"
        description="Learn how conversational AI and task automation are automating Helpdesk tasks using workativ assistant chatbot that integrates with IT Service Management(ITSM)."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            How Digital Assistants powered by AI and Automation
                            make a hugely positive difference to the Help Desk
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          The growth of Artificial Intelligence has had an
                          enormous spurt in the last few years. The use of AI in
                          Helpdesk Automation is reducing the burden on
                          professionals. Digital assistant integrates with IT
                          Service Management(ITSM) and acts as a virtual support
                          agent.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 class="font-section-sub-header-small-bold">
                          Increased Productivity
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          If the volume of support tickets is high, the support
                          agents will be unable to handle it, and there will be
                          a pile-up of queries waiting to be resolved. Repeated
                          questions eat up the time of support agents. For
                          instance, a query like a password reset requires a
                          particular flow of steps. Therefore, intelligent
                          self-service options form an integral part of IT
                          Helpdesk Automation — they help in faster resolution
                          as well as effective utilization of resources. The
                          intelligent self-service helps to ask questions and
                          quickly get the required answers using the service
                          desk.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Intelligent assistance in the form of a digital
                          assistant helps employees turn to the digital
                          assistant for support instead of the help desk. This
                          gives employees a better experience and more time to
                          do productive work instead of back and forth calls and
                          emails to help desk.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          <a href="https://workativ.com/conversational-ai-platform/call-deflection">
                            {" "}
                            Call deflection solution
                          </a>
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          There’s a new method to cut down calls to the helpdesk
                          – employees would only need to have the means to
                          resolve issues on their own without having to ask the
                          helpdesk support team. It reduces helpdesk cost and
                          helps resolve repetitive issues.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Some of the helpdesk tasks that can be automated
                          include email to ticket conversions, ticket routing,
                          and ticket categorization. Call deflection helps
                          offload a part of the call volume to the helpdesk. A
                          virtual agent resolves the issue without an employee
                          having to wait — the solution is provided quickly.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A digital assistant enables conversational
                          self-service. Employees prefer to receive instant
                          support, and are not new to messaging. So conversing
                          with a digital assistant in the form of texts provides
                          a good experience and satisfactory support. Getting
                          employees to engage with the digital assistant is an
                          effective call deflection solution.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          <a href="https://workativ.com/conversational-ai-platform/cost-reduction?utm_source=blog&utm_medium=organic&utm_campaign=website%20blogs&utm_term=internal&utm_content=How%20AI%20and%20Automation%20is%20making%20a%20hugely%20positive%20difference%20to%20the%20helpdesk">
                            Reduced Cost
                          </a>
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Powered by artificial intelligence, digital assistants
                          have emerged as new-age tools that transform the way
                          professionals work. Digital assistants are playing an
                          important part in increasing productivity and also
                          bringing down costs for businesses.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          A lot can be saved on rising support ticket costs by
                          using IT Ticketing automation. The{" "}
                          <a href="https://workativ.com/conversational-ai-platform/innovative-technology?utm_source=blog&utm_medium=organic&utm_campaign=website%20blogs&utm_term=internal&utm_content=How%20AI%20and%20Automation%20is%20making%20a%20hugely%20positive%20difference%20to%20the%20helpdesk">
                            ROI of IT Helpdesk Automation is high.
                          </a>{" "}
                          It gets rid of repetitive work. IT agent doesn’t need
                          to be involved. This can even bring down the capital
                          cost. Self-service replaces employees in IT-support
                          aided tickets. That’s a considerable cost saving
                          indeed!
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The cost per ticket can be greatly reduced by using AI
                          combined with automation. This is possible thanks to a
                          reduction in MTTR.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          <a href="https://workativ.com/conversational-ai-platform/general-vs-true-automation?utm_source=blog&utm_medium=organic&utm_campaign=website%20blogs&utm_term=internal&utm_content=How%20AI%20and%20Automation%20is%20making%20a%20hugely%20positive%20difference%20to%20the%20helpdesk">
                            IT Helpdesk Automation Vs ITSM tools
                          </a>
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          ITSM tools focus only on automating tasks like ticket
                          routing and ticket classification. They don’t
                          integrate well with third-party applications. ITSM
                          tools can’t resolve issues or service requests by
                          itself. The portal is still static and requires
                          resources to take action.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                            AI in helpdesk automation
                          </a>
                          brings end-to-end service delivery and support.
                        </p>

                        <h6 class="font-section-sub-header-small-bold">
                          Improves the quality of the service desk
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          When the self-service of traditional support channels
                          fails, it decreases the quality of the service desk.
                          More calls will require more service desk agents. It
                          involves a higher bandwidth with different teams
                          operating in different shifts to provide service 24×7.
                          According to Gartner,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                            IT service desks utilizing machine learning-enhanced
                            technology
                          </a>{" "}
                          and AI will help organizations stay on top and free up
                          to 30% of support capacity for higher-value tasks.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Businesses are now slowly recognizing the value that
                          conversational AI brings to the table and they are
                          looking for{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                            digital assistants to transform the help desk.
                          </a>{" "}
                          Through such small AI initiatives like incorporating
                          digital assistant for support, the help desk becomes
                          cost-efficient, effective and helps in bringing down
                          help desk costs.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Reduction in operational costs and reduced MTTR help
                          provide efficient IT support. Workativ Assistant is
                          one such software that helps in IT Ticketing
                          Automation. Various helpdesk automation ideas can make
                          a difference to the helpdesk if implemented with the
                          right software.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To see the difference with Workativ Assistant,
                          <a href="https://workativ.com/conversational-ai-platform">
                            sign up for a FREE now{" "}
                          </a>{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/5-help-desk-tasks-everyone-should-automate">
                                5 Help Desk Tasks Everyone Should Automate
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                                {" "}
                                Helpdesk vs Service desk
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/the-service-desk-trends-and-priorities-for-2022">
                                The Service Desk– Trends and priorities for 2022
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}
            {/* 
            <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
